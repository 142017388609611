(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sport-common/assets/javascripts/sport-utils.js') >= 0) return;  svs.modules.push('/components/sport/sport-common/assets/javascripts/sport-utils.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
svs.sport = svs.sport || {};
svs.sport.sport_common = svs.sport.sport_common || {};

function getGrasrotenMessage() {
  return getSponsorships().then(function () {
    let receivers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return svs.sport.sport_common.formatMessage(receivers.filter(receiver => !receiver.forcedInvalidation));
  }).catch(error => {
    svs.sport_common_logger.error('Failed to fetch sponsorship, error: ', error);
    return '';
  });
}
function getGrasrotenMessageForDialogOrToaster() {
  const grasroten = {
    hasSponsorship: false
  };
  return getSponsorships().then(receivers => {
    if (receivers && receivers.length) {
      const shortNames = receivers.map(receiver => receiver.shortname);

      const uniqueShortNames = [];
      shortNames.forEach(name => {
        if (uniqueShortNames.indexOf(name) === -1) {
          uniqueShortNames.push(name);
        }
      });


      grasroten.thankYouMessage = "<div>".concat(shortNames.length < 2 ? shortNames[0] : "".concat(shortNames.slice(0, -1).join(', '), " och ").concat(shortNames.slice(-1)), " tackar f\xF6r ditt st\xF6d via Gr\xE4sroten!</div>");
      grasroten.hasSponsorship = true;
    }
    return grasroten;
  }).catch(error => {
    svs.sport_common_logger.error('Failed to fetch sponsorship, error: ', error);
    return {};
  });
}

function getContextualGrasrotenDialog() {
  return getGrasrotenMessage().then(msg => {
    let dialog;
    if (msg && msg.includes('Du vet väl att')) {
      dialog = new svs.components.dialog.Confirm({
        area: svs.components.dialog.area.TOASTER,
        branding: svs.components.dialog.branding.SPORT,
        icon: 'favorites',
        title: 'Stötta dina favoritföreningar med Gräsroten',
        message: [{
          type: svs.components.dialog.message.TEXT,
          text: 'Välj upp till tre favoritföreningar som får ditt stöd varje gång du spelar.'
        }],
        actions: [{
          title: 'Påminn mig senare'
        }, {
          title: 'Välj favoriter',
          callback: () => {
            location.href = svs.core.urlMapping.get('grassrootsHome');
          }
        }]
      });
    } else {
      dialog = new svs.components.dialog.Confirm({
        branding: svs.components.dialog.branding.SPORT,
        icon: 'thumbs-up',
        title: msg.split('</br>').join(''),
        actions: [{
          title: 'Okej'
        }]
      });
    }
    return dialog;
  }).catch(error => {
    svs.sport_common_logger.error('Failed to create Grasroten dialog, error: ', error);
    return undefined;
  });
}

function formatMessage(receivers) {
  let message = '';
  if (receivers && receivers.length) {
    const shortNames = receivers.map(receiver => receiver.shortname);
    message += "<div>".concat(shortNames.length < 2 ? shortNames[0] : "".concat(shortNames.slice(0, -1).join(', '), " och ").concat(shortNames.slice(-1)), " tackar f\xF6r ditt st\xF6d via Gr\xE4sroten!</div></br>");
  } else {
    message += "<div>Du vet v\xE4l att du kan st\xF6tta din favoritf\xF6rening varje g\xE5ng du spelar.</div><div><a href=\"".concat(svs.core.urlMapping.get('grassrootsHome'), "\" class=\"alert-text-link\">V\xE4lj f\xF6reningar h\xE4r.</a></div></br>");
  }
  return message;
}

async function checkExclusionOnVertical() {
  await svs.accountservices.player_exclusions.canPlayOnVertical('tipsspel');
}

function getSponsorships() {
  const deferred = $.Deferred();
  svs.core.jupiter.call({
    method: 'GET',
    path: '/player/1/sponsorship/receiverselection?active=true'
  }, data => {
    try {
      deferred.resolve(data.receivers);
    } catch (error) {
      deferred.reject(error);
    }
  }, error => {
    deferred.reject(error);
  });
  return deferred.promise();
}
function showErrorDialog(title, message) {
  let dialogType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : svs.components.dialog.type.ERROR;
  const dialogConfig = {
    branding: svs.components.dialog.branding.SPORT,
    icon: 'help-2',
    type: dialogType,
    actions: [{
      title: 'Okej'
    }]
  };
  if (title) {
    dialogConfig.title = title;
  } else {
    dialogConfig.title = 'Nu gick något fel';
  }
  if (message) {
    dialogConfig.message = [{
      type: svs.components.dialog.message.TEXT,
      text: message
    }];
  }
  svs.components.dialog.api.add(new svs.components.dialog.Confirm(dialogConfig));
}
const exportThis = {
  checkExclusionOnVertical,
  formatMessage,
  getGrasrotenMessage,
  getContextualGrasrotenDialog,
  getGrasrotenMessageForDialogOrToaster,
  showErrorDialog
};
if (svs.isServer) {
  module.exports = exportThis;
} else {
  svs.sport.sport_common = _objectSpread(_objectSpread({}, svs.sport.sport_common), exportThis);
}

 })(window);