(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sport-common/prescript.js') >= 0) return;  svs.modules.push('/components/sport/sport-common/prescript.js');
'use strict';

(function(svs) {
  svs = svs || {};
  svs.sport = svs.sport || {};
  svs.sport.sport_common = svs.sport.sport_common || {};

  svs.sport_common_logger = new svs.core.log.Logger('components:oddset_common');
})(svs);


 })(window);